<template>
  <ThresholdsStyled>
    <div class="thresholds">SET THRESHOLDS</div>
    <SectionStyled>
      <div class="thresholds">Pressure (kPa)</div>
      <InputField :labelWidth="9" class="lowCritical">
        <template v-slot:input>
          <input
            v-model.number="$v.defaultThresholds['pressure_critically_low'].$model"
            placeholder="Low Critical Threshold"
            @input="handleChange"
          />
        </template>
        <template v-slot:label>
          <span>Low critical</span>
        </template>
        <template v-slot:errors v-if="$v.defaultThresholds['pressure_critically_low'].$dirty">
          <div class="error" v-if="!$v.defaultThresholds['pressure_critically_low'].required">Field is required</div>
          <div class="error" v-if="!$v.defaultThresholds['pressure_critically_low'].decimal">Field must be a float/decimal number</div>
        </template>
      </InputField>
      <InputField :labelWidth="9" class="low">
        <template v-slot:input>
          <input v-model.number="$v.defaultThresholds['pressure_low'].$model" placeholder="Low Threshold" @input="handleChange" />
        </template>
        <template v-slot:label>
          <span>Low</span>
        </template>
        <template v-slot:errors v-if="$v.defaultThresholds['pressure_low'].$dirty">
          <div class="error" v-if="!$v.defaultThresholds['pressure_low'].required">Field is required</div>
          <div class="error" v-if="!$v.defaultThresholds['pressure_low'].decimal">Field must be a float/decimal number</div>
        </template>
      </InputField>
      <InputField :labelWidth="9" class="high">
        <template v-slot:input>
          <input v-model.number="$v.defaultThresholds['pressure_high'].$model" placeholder="High Threshold" @input="handleChange" />
        </template>
        <template v-slot:label>
          <span>High</span>
        </template>
        <template v-slot:errors v-if="$v.defaultThresholds['pressure_high'].$dirty">
          <div class="error" v-if="!$v.defaultThresholds['pressure_high'].required">Field is required</div>
          <div class="error" v-if="!$v.defaultThresholds['pressure_high'].decimal">Field must be a float/decimal number</div>
        </template>
      </InputField>
      <InputField :labelWidth="9" class="highCritical">
        <template v-slot:input>
          <input
            v-model.number="$v.defaultThresholds['pressure_critically_high'].$model"
            placeholder="High Critical Threshold"
            @input="handleChange"
          />
        </template>
        <template v-slot:label>
          <span>High critical</span>
        </template>
        <template v-slot:errors v-if="$v.defaultThresholds['pressure_critically_high'].$dirty">
          <div class="error" v-if="!$v.defaultThresholds['pressure_critically_high'].required">Field is required</div>
          <div class="error" v-if="!$v.defaultThresholds['pressure_critically_high'].decimal">Field must be a float/decimal number</div>
        </template>
      </InputField>
    </SectionStyled>
    <SectionStyled>
      <div class="thresholds">Temperature</div>
      <InputField :labelWidth="9" class="normal">
        <template v-slot:input>
          <input v-model.number="$v.defaultThresholds['temperature_normal'].$model" placeholder="normal Threshold" @input="handleChange" />
        </template>
        <template v-slot:label>
          <span>Normal</span>
        </template>
        <template v-slot:errors v-if="$v.defaultThresholds['temperature_normal'].$dirty">
          <div class="error" v-if="!$v.defaultThresholds['temperature_normal'].required">Field is required</div>
          <div class="error" v-if="!$v.defaultThresholds['temperature_normal'].decimal">Field must be a float/decimal number</div>
        </template>
      </InputField>
      <InputField :labelWidth="9" class="critical">
        <template v-slot:input>
          <input
            v-model.number="$v.defaultThresholds['temperature_critical'].$model"
            placeholder="Critical Threshold"
            @input="handleChange"
          />
        </template>
        <template v-slot:label>
          <span>Critical</span>
        </template>
        <template v-slot:errors v-if="$v.defaultThresholds['temperature_critical'].$dirty">
          <div class="error" v-if="!$v.defaultThresholds['temperature_critical'].required">Field is required</div>
          <div class="error" v-if="!$v.defaultThresholds['temperature_critical'].decimal">Field must be a float/decimal number</div>
        </template>
      </InputField>
    </SectionStyled>
    <RulesStyled>
      <RuleStyled v-for="threshold in thresholds" :key="threshold.id" :class="threshold.severity.toLowerCase()">
        <span> Type: {{ threshold.type }}</span>
        <span> Min: {{ threshold.min || '-∞' }} </span>
        <span> Max: {{ threshold.max || '∞' }} </span>
        <span> Severity: {{ threshold.severity }} </span>
      </RuleStyled>
    </RulesStyled>
  </ThresholdsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { InputField } from '@common/components'
import chroma from 'chroma-js'
import { required, decimal } from 'vuelidate/lib/validators'

const ThresholdsStyled = styled('div')`
  display: flex;
  flex-direction: column;
`
const SectionStyled = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  .thresholds {
    padding-bottom: 1rem;
  }
`
const RulesStyled = styled('div')`
  display: grid;
  grid-template-columns: min-content;
  grid-gap: 0.2rem;
`

const RuleStyled = styled('div')`
  display: grid;
  grid-template-columns: 11rem 5em 5rem 13rem;
  padding: 0.2rem;
  border-radius: 3px;
  &.critically_low,
  &.critically_high,
  &.critical {
    background: ${({ theme }) => chroma(theme.colors.red).alpha(0.5).css()};
  }
  &.low,
  &.high {
    background: ${({ theme }) => chroma(theme.colors.yellow).alpha(0.5).css()};
  }
  &.normal {
    background: ${({ theme }) => chroma(theme.colors.green).alpha(0.5).css()};
  }
`

export default {
  props: {
    thresholds: {
      type: Array,
      required: false,
    },
    mode: {
      type: String,
      default: 'create',
    },
  },
  components: {
    ThresholdsStyled,
    SectionStyled,
    RulesStyled,
    RuleStyled,
    InputField,
  },
  data() {
    return {
      // mode: 'create',
      defaultThresholds: {
        pressure_low: '',
        pressure_critically_low: '',
        pressure_high: '',
        pressure_critically_high: '',
        temperature_normal: '',
        temperature_critical: '',
      },
    }
  },
  validations: {
    defaultThresholds: {
      pressure_low: {
        required,
        decimal,
      },
      pressure_critically_low: {
        required,
        decimal,
      },
      pressure_high: {
        required,
        decimal,
      },
      pressure_critically_high: {
        required,
        decimal,
      },
      temperature_normal: {
        required,
        decimal,
      },
      temperature_critical: {
        required,
        decimal,
      },
    },
  },

  methods: {
    initDefaultValues() {
      this.defaultThresholds.pressure_critically_low = this.thresholds.find(
        f => f.type === 'PRESSURE' && f.severity === 'CRITICALLY_LOW',
      )?.max
      this.defaultThresholds.pressure_low = this.thresholds.find(f => f.type === 'PRESSURE' && f.severity === 'LOW')?.max
      this.defaultThresholds.pressure_high = this.thresholds.find(f => f.type === 'PRESSURE' && f.severity === 'HIGH')?.min
      this.defaultThresholds.pressure_critically_high = this.thresholds.find(
        f => f.type === 'PRESSURE' && f.severity === 'CRITICALLY_HIGH',
      )?.min
      this.defaultThresholds.temperature_normal = this.thresholds.find(f => f.type === 'TEMPERATURE' && f.severity === 'HIGH')?.min
      this.defaultThresholds.temperature_critical = this.thresholds.find(f => f.type === 'TEMPERATURE' && f.severity === 'HIGH')?.max
    },
    handleChange() {
      if (!this.$v.$invalid) {
        const pressure_critically_low = this.thresholds.find(f => {
          return f.severity === 'CRITICALLY_LOW' && f.type === 'PRESSURE'
        }) || {
          severity: 'CRITICALLY_LOW',
          type: 'PRESSURE',
        }
        pressure_critically_low.threshold = null // TODO: remove after deprecation phase
        pressure_critically_low.min = null
        pressure_critically_low.max = this.defaultThresholds.pressure_critically_low

        const pressure_low = this.thresholds.find(f => {
          return f.severity === 'LOW' && f.type === 'PRESSURE'
        }) || {
          severity: 'LOW',
          type: 'PRESSURE',
        }
        pressure_low.threshold = null // TODO: remove after deprecation phase
        pressure_low.min = this.defaultThresholds.pressure_critically_low
        pressure_low.max = this.defaultThresholds.pressure_low

        const pressure_normal = this.thresholds.find(f => {
          return f.severity === 'NORMAL' && f.type === 'PRESSURE'
        }) || {
          severity: 'NORMAL',
          type: 'PRESSURE',
        }
        pressure_normal.threshold = null // TODO: remove after deprecation phase
        pressure_normal.min = this.defaultThresholds.pressure_low
        pressure_normal.max = this.defaultThresholds.pressure_high

        const pressure_high = this.thresholds.find(f => {
          return f.severity === 'HIGH' && f.type === 'PRESSURE'
        }) || {
          severity: 'HIGH',
          type: 'PRESSURE',
        }
        pressure_high.threshold = null // TODO: remove after deprecation phase
        pressure_high.min = this.defaultThresholds.pressure_high
        pressure_high.max = this.defaultThresholds.pressure_critically_high

        const pressure_critically_high = this.thresholds.find(f => {
          return f.severity === 'CRITICALLY_HIGH' && f.type === 'PRESSURE'
        }) || {
          severity: 'CRITICALLY_HIGH',
          type: 'PRESSURE',
        }
        pressure_critically_high.threshold = null // TODO: remove after deprecation phase
        pressure_critically_high.min = this.defaultThresholds.pressure_critically_high
        pressure_critically_high.max = null

        const temperature_normal = this.thresholds.find(f => {
          return f.severity === 'NORMAL' && f.type === 'TEMPERATURE'
        }) || {
          severity: 'NORMAL',
          type: 'TEMPERATURE',
        }
        temperature_normal.threshold = null // TODO: remove after deprecation phase
        temperature_normal.min = null
        temperature_normal.max = this.defaultThresholds.temperature_normal

        const temperature_high = this.thresholds.find(f => {
          return f.severity === 'HIGH' && f.type === 'TEMPERATURE'
        }) || {
          severity: 'HIGH',
          type: 'TEMPERATURE',
        }
        temperature_high.threshold = null // TODO: remove after deprecation phase
        temperature_high.min = this.defaultThresholds.temperature_normal
        temperature_high.max = this.defaultThresholds.temperature_critical

        const temperature_critically_high = this.thresholds.find(f => {
          return f.severity === 'CRITICALLY_HIGH' && f.type === 'TEMPERATURE'
        }) || {
          severity: 'CRITICALLY_HIGH',
          type: 'TEMPERATURE',
        }
        temperature_critically_high.threshold = null // TODO: remove after deprecation phase
        temperature_critically_high.min = this.defaultThresholds.temperature_critical
        temperature_critically_high.max = null

        this.$emit('update:thresholds', [
          pressure_critically_low,
          pressure_low,
          pressure_normal,
          pressure_high,
          pressure_critically_high,
          temperature_normal,
          temperature_high,
          temperature_critically_high,
        ])
      }
    },
  },
  mounted() {
    this.initDefaultValues()
  },
}
</script>
