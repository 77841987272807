var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelCreater', {
    attrs: {
      "title": _vm.title,
      "loading": _vm.isLoading,
      "errors": _vm.errors,
      "canCreate": _vm.canCreate
    },
    on: {
      "create": _vm.create
    }
  }, [_c('CreateTyreStyled', [_c('InputField', {
    staticClass: "model",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.model.$model,
            expression: "$v.model.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "Model"
          },
          domProps: {
            "value": _vm.$v.model.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.model, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Model")])];
      },
      proxy: true
    }, _vm.$v.model.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.model.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "serialNumber",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.serialNumber.$model,
            expression: "$v.serialNumber.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "serialNumber"
          },
          domProps: {
            "value": _vm.$v.serialNumber.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.serialNumber, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("serial Number")])];
      },
      proxy: true
    }, _vm.$v.serialNumber.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.serialNumber.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('TyreThresholds', {
    attrs: {
      "thresholds": _vm.thresholds
    },
    on: {
      "update:thresholds": function updateThresholds($event) {
        _vm.thresholds = $event;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }