var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ThresholdsStyled', [_c('div', {
    staticClass: "thresholds"
  }, [_vm._v("SET THRESHOLDS")]), _c('SectionStyled', [_c('div', {
    staticClass: "thresholds"
  }, [_vm._v("Pressure (kPa)")]), _c('InputField', {
    staticClass: "lowCritical",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.number",
            value: _vm.$v.defaultThresholds['pressure_critically_low'].$model,
            expression: "$v.defaultThresholds['pressure_critically_low'].$model",
            modifiers: {
              "number": true
            }
          }],
          attrs: {
            "placeholder": "Low Critical Threshold"
          },
          domProps: {
            "value": _vm.$v.defaultThresholds['pressure_critically_low'].$model
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.defaultThresholds['pressure_critically_low'], "$model", _vm._n($event.target.value));
            }, _vm.handleChange],
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Low critical")])];
      },
      proxy: true
    }, _vm.$v.defaultThresholds['pressure_critically_low'].$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.defaultThresholds['pressure_critically_low'].required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.defaultThresholds['pressure_critically_low'].decimal ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must be a float/decimal number")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "low",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.number",
            value: _vm.$v.defaultThresholds['pressure_low'].$model,
            expression: "$v.defaultThresholds['pressure_low'].$model",
            modifiers: {
              "number": true
            }
          }],
          attrs: {
            "placeholder": "Low Threshold"
          },
          domProps: {
            "value": _vm.$v.defaultThresholds['pressure_low'].$model
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.defaultThresholds['pressure_low'], "$model", _vm._n($event.target.value));
            }, _vm.handleChange],
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Low")])];
      },
      proxy: true
    }, _vm.$v.defaultThresholds['pressure_low'].$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.defaultThresholds['pressure_low'].required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.defaultThresholds['pressure_low'].decimal ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must be a float/decimal number")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "high",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.number",
            value: _vm.$v.defaultThresholds['pressure_high'].$model,
            expression: "$v.defaultThresholds['pressure_high'].$model",
            modifiers: {
              "number": true
            }
          }],
          attrs: {
            "placeholder": "High Threshold"
          },
          domProps: {
            "value": _vm.$v.defaultThresholds['pressure_high'].$model
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.defaultThresholds['pressure_high'], "$model", _vm._n($event.target.value));
            }, _vm.handleChange],
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("High")])];
      },
      proxy: true
    }, _vm.$v.defaultThresholds['pressure_high'].$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.defaultThresholds['pressure_high'].required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.defaultThresholds['pressure_high'].decimal ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must be a float/decimal number")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "highCritical",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.number",
            value: _vm.$v.defaultThresholds['pressure_critically_high'].$model,
            expression: "$v.defaultThresholds['pressure_critically_high'].$model",
            modifiers: {
              "number": true
            }
          }],
          attrs: {
            "placeholder": "High Critical Threshold"
          },
          domProps: {
            "value": _vm.$v.defaultThresholds['pressure_critically_high'].$model
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.defaultThresholds['pressure_critically_high'], "$model", _vm._n($event.target.value));
            }, _vm.handleChange],
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("High critical")])];
      },
      proxy: true
    }, _vm.$v.defaultThresholds['pressure_critically_high'].$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.defaultThresholds['pressure_critically_high'].required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.defaultThresholds['pressure_critically_high'].decimal ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must be a float/decimal number")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  })], 1), _c('SectionStyled', [_c('div', {
    staticClass: "thresholds"
  }, [_vm._v("Temperature")]), _c('InputField', {
    staticClass: "normal",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.number",
            value: _vm.$v.defaultThresholds['temperature_normal'].$model,
            expression: "$v.defaultThresholds['temperature_normal'].$model",
            modifiers: {
              "number": true
            }
          }],
          attrs: {
            "placeholder": "normal Threshold"
          },
          domProps: {
            "value": _vm.$v.defaultThresholds['temperature_normal'].$model
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.defaultThresholds['temperature_normal'], "$model", _vm._n($event.target.value));
            }, _vm.handleChange],
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Normal")])];
      },
      proxy: true
    }, _vm.$v.defaultThresholds['temperature_normal'].$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.defaultThresholds['temperature_normal'].required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.defaultThresholds['temperature_normal'].decimal ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must be a float/decimal number")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "critical",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.number",
            value: _vm.$v.defaultThresholds['temperature_critical'].$model,
            expression: "$v.defaultThresholds['temperature_critical'].$model",
            modifiers: {
              "number": true
            }
          }],
          attrs: {
            "placeholder": "Critical Threshold"
          },
          domProps: {
            "value": _vm.$v.defaultThresholds['temperature_critical'].$model
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.defaultThresholds['temperature_critical'], "$model", _vm._n($event.target.value));
            }, _vm.handleChange],
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Critical")])];
      },
      proxy: true
    }, _vm.$v.defaultThresholds['temperature_critical'].$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.defaultThresholds['temperature_critical'].required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.defaultThresholds['temperature_critical'].decimal ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must be a float/decimal number")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  })], 1), _c('RulesStyled', _vm._l(_vm.thresholds, function (threshold) {
    return _c('RuleStyled', {
      key: threshold.id,
      class: threshold.severity.toLowerCase()
    }, [_c('span', [_vm._v(" Type: " + _vm._s(threshold.type))]), _c('span', [_vm._v(" Min: " + _vm._s(threshold.min || '-∞') + " ")]), _c('span', [_vm._v(" Max: " + _vm._s(threshold.max || '∞') + " ")]), _c('span', [_vm._v(" Severity: " + _vm._s(threshold.severity) + " ")])]);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }