<template>
  <ModelCreater :title="title" :loading="isLoading" :errors="errors" :canCreate="canCreate" @create="create">
    <CreateTyreStyled>
      <InputField :labelWidth="6" class="model">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.model.$model" placeholder="Model" />
        </template>
        <template v-slot:label>
          <span>Model</span>
        </template>
        <template v-slot:errors v-if="$v.model.$dirty">
          <div class="error" v-if="!$v.model.required">Field is required</div>
        </template>
      </InputField>
      <InputField :labelWidth="9" class="serialNumber">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.serialNumber.$model" placeholder="serialNumber" />
        </template>
        <template v-slot:label>
          <span>serial Number</span>
        </template>
        <template v-slot:errors v-if="$v.serialNumber.$dirty">
          <div class="error" v-if="!$v.serialNumber.required">Field is required</div>
        </template>
      </InputField>
      <TyreThresholds :thresholds.sync="thresholds" />
    </CreateTyreStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelCreater from '@/components/misc/ModelCreater'
import { InputField } from '@common/components'
import TyreThresholds from './TyreThresholds'
import { required } from 'vuelidate/lib/validators'

import TYRE_CREATE from '#/graphql/tyres/create.gql'

const CreateTyreStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;
`

export default {
  components: {
    CreateTyreStyled,
    ModelCreater,
    InputField,
    TyreThresholds,
    // Multiselect,
  },
  data() {
    return {
      model: '',
      serialNumber: '',
      errors: [],
      isLoading: false,
      isCreating: false,
      thresholds: [],
      enableSave: false,
    }
  },
  computed: {
    title() {
      return `Create Tyre`
    },
    canCreate() {
      return !this.$v.$invalid && !this.isCreating && this.thresholds.length === 8
    },
  },
  validations: {
    model: {
      required,
    },
    serialNumber: {
      required,
    },
  },
  methods: {
    createThresholds(thresholds, enableSave) {
      this.thresholds = thresholds
      this.enableSave = enableSave
    },
    async create() {
      this.isCreating = true
      const data = {
        serialNumber: this.serialNumber,
        model: this.model,
        thresholds: {},
      }

      data.thresholds.createMany = {
        data: this.thresholds.filter(t => !t.id),
      }

      const res = await this.$apollo.mutate({
        mutation: TYRE_CREATE,
        variables: {
          data,
        },
      })
      const id = res?.data?.createTyre?.id
      if (id) {
        this.$emit('reload')
        this.$router.push({
          name: 'tyre',
          params: {
            id,
          },
        })
      }
      this.isCreating = false
      this.$emit('update-tyres-list')
    },
  },
}
</script>
